import React, { createContext, useContext, ReactNode } from 'react';

export type RootContainerConfigContextType = Record<string, any>;

const RootContainerConfigContext = createContext<RootContainerConfigContextType | undefined>(undefined);

export const RootContainerConfigProvider: React.FC<{ config: RootContainerConfigContextType, children: ReactNode }> = ({ config, children }) => {
  return (
    <RootContainerConfigContext.Provider value={{ config }}>
      {children}
    </RootContainerConfigContext.Provider>
  );
};

export const useRootContainerConfig = (): RootContainerConfigContextType => {
  const context = useContext(RootContainerConfigContext);
  if (context === undefined) {
    throw new Error('useRootContainerConfig must be used within a RootContainerConfigProvider');
  }
  return context;
};
