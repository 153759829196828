// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const AuthCookieKeys = {
  Name: "name",
  Username: "username",
};

export const Auth0Config = {
  domain : process.env.REACT_APP_AUTH0_DOMAIN,
  clientId : process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience : process.env.REACT_APP_AUTH0_AUDIENCE,
  scope : process.env.REACT_APP_AUTH0_SCOPE,
};