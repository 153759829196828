import { Button, Box, Grid } from "@mui/material";
import { useCss } from "kremling";

type Props = {
  login: () => void
};

const Login = ({ login }: Props) => {
  const scope = useCss(css);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}>
      <Box className="container" {...scope}>
        <Button variant="contained" color="primary" onClick={login}>
          Login with SSO
        </Button>
      </Box>
    </Grid>
  );
};

const css = `
& .container {
  display: flex;
  justify-content: center;
}
`;

export default Login;
