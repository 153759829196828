import { useState, MouseEvent } from "react";
import { Box } from "@mui/material";
import TopNav from "./TopNav";
import { useRootContainerConfig } from '../contexts';

type Props = {
  user: {
    name: string;
    username: string;
  };
  logout: () => Promise<void>;
};

const NavigationContainer = ({ user, logout }: Props): JSX.Element => {
  const { config } = useRootContainerConfig();


  const handleNavigation = (href: string) => {
    window.location.href = href;
  };

  return (
    <Box>
      <TopNav
        user={user}
        navLinks={config.navLinks}
        logout={logout}
        handleNavigation={handleNavigation}
      />
    </Box>
  );
};

export default NavigationContainer;