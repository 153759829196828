import Navigation from "../Navigation";
import { useAuth0 } from '@auth0/auth0-react';

type PropTypes = {
  user: {
    name: string,
    username: string
  };
};

const Authenticated = ({user}: PropTypes): JSX.Element => {
  const {
    isAuthenticated,
    logout,
  } = useAuth0();
  const formattedLogout = () => { return logout({ 
    logoutParams: {
      returnTo: window.location.origin
    }
  })}
  return  isAuthenticated && (<Navigation user={user} logout={formattedLogout} />);
};

export default Authenticated;
