import { useState, MouseEvent } from "react";
import {
  AppBar,
  Toolbar,
  Avatar,
  Box,
  Menu,
  MenuItem,
  Tooltip,
  Icon,
  Typography,
  Divider,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AppsIcon from "@mui/icons-material/Apps";
import { DynamicIcon } from "../utility";
import { useCss } from "kremling";

type Props = {
  user: {
    name: string;
    username: string;
  };
  navLinks: Array<{label: string, href: string, icon: string}>;
  logout: () => Promise<void>;
  handleNavigation: (href: string) => void;
};

const TopNav = ({
  user,
  navLinks,
  logout,
  handleNavigation
}: Props): JSX.Element => {
  const scope = useCss(css);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box {...scope}>
      <AppBar
        position="relative"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar className="toolbar">
          <Icon style={{ fontSize: 42, width: 90, marginRight: 10 }}>
            <img
              src="https://cdn.brinkpos.net/images/PAR-LOGO_White_2023.png"
              height={42}
              width={90}
            />
          </Icon>
          <IconButton
            className="iconButton"
            onClick={handleMenuClick}
            color="inherit"
          >
            <AppsIcon fontSize="large" />
          </IconButton>
          <div style={{ flexGrow: 1 }} />
          <Tooltip title={`Logged in as ${user.name} (${user.username})`}>
            <IconButton onClick={handleClick} edge="start" color="inherit">
              <Avatar alt={user.name} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Menu
        id="app-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "250px",
            border: "1px solid #ccc",
            "& .MuiMenuItem-root": {
              fontSize: "1rem",
              padding: "10px 20px",
            },
          },
        }}
      >
        <Typography
          variant="subtitle1"
          color="InactiveCaptionText"
          marginLeft={2}
          gutterBottom
        >
          Your Apps:
        </Typography>
        <Divider />
        {navLinks.map((navLink, index) => (
          <MenuItem key={index} onClick={() => handleNavigation(navLink.href)}>
            <DynamicIcon iconName={navLink.icon} sx={{ mr: 1 }} color="action" />
            <Typography>{navLink.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiPaper-root": {
            border: "1px solid #ccc",
          },
        }}
      >
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

const css = `
`;

export default TopNav;
