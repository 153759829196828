import { Auth0Config } from "../authConfig";
import Login from "./Login";
import { useAuth0 } from "@auth0/auth0-react";

const LoginContainer = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    !isAuthenticated && (
      <Login
        login={() =>
          loginWithRedirect({
            authorizationParams: {
              redirect_uri: window.location.origin,
              audience: Auth0Config.audience,
              scope: Auth0Config.scope,
            },
          })
        }
      />
    )
  );
};

export default LoginContainer;
