import { Container, CssBaseline } from "@mui/material";
import { CookiesProvider } from "react-cookie";
import { Auth0Config } from "./authConfig";
import { AppWithAuthRequired } from "./App";
import { Auth0Provider } from '@auth0/auth0-react';
import { RootContainerConfigProvider, RootContainerConfigContextType } from './contexts';


const Root = ({config}: RootContainerConfigContextType) => {
  const onRedirectCallback = (appState) => {
    window.history.replaceState(
      {},
      document.title,
      '/topology'
    );
  };

  return (
    <RootContainerConfigProvider config={config}>
      <Auth0Provider
        domain={Auth0Config.domain}
        clientId={Auth0Config.clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: Auth0Config.audience,
          scope: Auth0Config.scope,
        }}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        onRedirectCallback={onRedirectCallback}
      >
        <CookiesProvider>
          <CssBaseline />
          <Container maxWidth={false} className="container" disableGutters>
            <AppWithAuthRequired />
          </Container>
        </CookiesProvider>
      </Auth0Provider>
    </RootContainerConfigProvider>
  );
};

export default Root;
