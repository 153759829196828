import { LinearProgress, ThemeProvider } from "@mui/material";
import Authenticated from "./authenticated";
import Login from "./login";
import theme from "./theme";
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Auth0Config } from "./authConfig";

const App = () => {
  const {
    isAuthenticated,
    isLoading,
  } = useAuth0();

  if (isLoading) {
    return (
      <LinearProgress/>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      {isAuthenticated ? <Authenticated /> : <Login />}
    </ThemeProvider>
  );
};

export const AppWithAuthRequired = withAuthenticationRequired(App, {
  onRedirecting: () => <LinearProgress/>,
  loginOptions: {
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: Auth0Config.audience,
      scope: Auth0Config.scope,
    }
  }
});
