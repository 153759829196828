import { Suspense, lazy } from "react";
import { Menu } from "@mui/icons-material";

const loadIcon = (iconName) => {
  return lazy(() => {
    return import("@mui/icons-material")
      .then((module) => {
        const IconComponent = module[iconName];
        if (!IconComponent) {
          throw new Error(
            `Icon "${iconName}" does not exist in @mui/icons-material.`
          );
        }
        return { default: IconComponent };
      })
      .catch((err) => {
        console.error(err);
        return { default: Menu };
      });
  });
};

export function DynamicIcon({ iconName, ...props }) {
  const IconComponent = loadIcon(iconName);

  return (
    <Suspense fallback={<Menu {...props} />}>
      <IconComponent {...props} />
    </Suspense>
  );
}
