import { useEffect } from "react";
import { useCookies } from "react-cookie";
import Authenticated from "./Authenticated";
import { AuthCookieKeys, Auth0Config } from "../authConfig";
import { useAuth0 } from "@auth0/auth0-react";

const AuthenticatedContainer = (): JSX.Element => {
  const { user } = useAuth0();
  const [cookies, setCookie, removeCookie] = useCookies([
    AuthCookieKeys.Name,
    AuthCookieKeys.Username,
  ]);

  const formattedUser = {
    name: user.nickname,
    username: user.email,
  };

  setCookie(AuthCookieKeys.Name, formattedUser.name, { path: "/" });
  setCookie(AuthCookieKeys.Username, formattedUser.username, { path: "/" });

  return <Authenticated user={formattedUser} />;
};

export default AuthenticatedContainer;
