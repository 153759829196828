import { Auth0Config } from "../authConfig";
import { Auth0Client, GenericError } from "@auth0/auth0-spa-js";
import { jwtDecode } from "jwt-decode";

export async function getTokenAsync() {
  const auth0 = new Auth0Client({
    domain: Auth0Config.domain,
    clientId: Auth0Config.clientId,
    cacheLocation: "localstorage",
    useRefreshTokens: true,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: Auth0Config.audience,
      scope: Auth0Config.scope,
    },
  });

  try {
    const token = await auth0.getTokenSilently();

    // check if the token is expired
    const decoded = jwtDecode(token);
    if (decoded.exp) {
      const expirationUtcTimestamp = decoded.exp * 1000;
      if (Date.now() > expirationUtcTimestamp) {
        throw new GenericError("token_expired", "Token has expired");
      }
    }

    return `Bearer ${token}`;
  } catch (e) {
    if (
      e.error === "missing_refresh_token" ||
      e.error === "login_required" ||
      e.error === "consent_required" ||
      e.error == "token_expired"
    ) {
      auth0.logout();
      throw new Error(`Error: ${e.error}. Please log in again.`);
    }

    throw new Error(e.error);
  }
}
